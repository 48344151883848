import React from "react"
import { Link } from 'gatsby'

import Layout from "~components/layout/layout.component"
import SEO from "~components/seo/seo.component"

const ContactPage = () => (
  <Layout>
    <SEO title="Constact" />
    <h1>Contact</h1>
    <p>
      <b>Mailing Address</b>
      <div>RainedOut</div>
      <div>525-K East Market Street, # 232</div>
      <div>Leesburg, VA 20176</div>
    </p>

    <p>
      <b>Leesburg Office</b>
      <div>202 Church Street, SE</div>
      <div>Suite 516</div>
      <div>Leesburg, VA 20175</div>
    </p>

    <p>
      <b>Technical Support</b><br/>
      <a href="mailto:care@rainedout.com">care@rainedout.com</a>
    </p>

    <p>
      <b>General E-mail & Media Inquiries</b><br/>
      <a href="mailto:contact@rainedout.com">contact@rainedout.com</a>
    </p>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default ContactPage
